import React from "react";
import suspendImage from "../images/suspend.png";
import Image from "next/image";
import Link from "next/link";
import {GetServerSideProps} from "next";
import {getSeparateDomain} from "../services/utils";
import generalAction from "../api/generalAction";
import shopApi from "../api/shop";

export default function Suspend({DomainName}: any) {
    const src = `${window.location.origin}${suspendImage?.src}`
    return (
        <div className="SuspendMain">
            <div className="SuspendBody container p-0">
                <div className="Suspend">
                    <div>
                        <div className="SuspendImage">
                            <Image
                                loader={() => src}
                                width={480}
                                height={429}
                                src={src}
                                alt="Suspend Image"
                            />
                        </div>
                    </div>

                    <h1>
                        {DomainName} <br/>
                        is no longer available
                    </h1>

                    <h3>
                        This website has been archived or suspended in accordance with our{" "}
                        <Link href="https://www.zegashop.com/web/terms-of-service/" prefetch={false}>
                            <a>
                                Terms Of Service.
                            </a>
                        </Link>
                    </h3>
                    <h5>
                        For more information, Please,{" "}
                        <Link href="https://www.zegashop.com/web/contact-us/" prefetch={false}>
                            <a target="_blank">
                                Contact us.
                            </a>
                        </Link>
                    </h5>
                </div>
            </div>
        </div>
    );
};


// TODO : if this code unessesary , delete is,maybe errror from this part of code
export const getServerSideProps: GetServerSideProps = async ({locale, locales, req, res}: any) => {
    let selectedCurrency: string = '';
    let selectedRate;
    const dbName = req.headers["x-forwarded-host"];
    const domain = req.defaultDB.db_name

    res.setHeader("Cache-Control", "public, s-maxage=10, stale-while-revalidate=59");

    ////CHECKING CURRENCY
    if (Object.keys(req.query).length > 0) {
        selectedCurrency = req.query.currencies;
    }
    const generalOptions = {
        locale,
        selectedCurrency,
        dbName,
        domain
    }

    const {
        locale: defaultLocaleSelected,
        rate,
        currency,
        dispatches
    } = await generalAction.action("general", generalOptions, req.m)

    ////GETTING RATE FOR CURRENCY
    if (Object.keys(req.query).length > 0) {
        selectedRate = rate.currencies_new?.find(
            (item: any) => item.code == selectedCurrency
        );
    }
    const selectedLocale = locale !== "catchAll" ? locale : defaultLocaleSelected;
    const metaTags = await shopApi.getHomeMetaAsync(domain, selectedLocale, req.m);


    const generalDispatches: any = {
        ...dispatches.clientSide,
        ...dispatches.serverSide,
    };
    return {
        props: {
            locale: selectedLocale,
            dispatches: generalDispatches,
            metas: metaTags,
            currency,
            rate: selectedRate?.exchange_rate?.rate || 1,
        },
    };
}
